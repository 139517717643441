import { graphql, StaticQuery } from 'gatsby'
import React, { useState } from 'react'
import Theme from '../components/Theme/Theme'
import SEO from "./seo"

const LayoutContainer = ({children, ...props}) => {

    return (
        <Theme {...props}>
            <SEO title={props.title} /> 
            {children}
        </Theme>
    )
}

export default LayoutContainer
