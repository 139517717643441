import React from 'react'
import ArticleBase from "./ArticleBase"
import ArticleBody from "./ArticleBody"
import ArticleGrid from "./ArticleGrid"
import ArticleHeading from "./ArticleHeading"
import ArticleContent from "./ArticleContent"

const Article = ({backgroundColor, title, heading, content}) => {

   return (
        <ArticleBase backgroundColor={backgroundColor}>
            <ArticleGrid>
                <ArticleBody>
                    <ArticleHeading variant="h1">{heading}</ArticleHeading>
                    <ArticleContent>{content}</ArticleContent>
                </ArticleBody>
            </ArticleGrid>
        </ArticleBase>        
    )


}

export default Article