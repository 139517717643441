import React from 'react'
import { graphql } from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'
import Container from '../containers/default'
import Layout from '../components/VerticalPages'
import Article from '../components/Article/Article'

export const query = graphql`
  query NotFoundPageQuery {
    page: sanityPage(_id: { regex: "/(drafts.|)404/" }) {
        _type
        id
        title
        content,
        links {
            ... on SanityPage {
                _id
                id
                _type
              title
              slug {
                current
              }
            }
            ... on SanityPerson {
                id
                _type
              name
              slug {
                current
              }
            }
        }
    }
  }
`
const NotFoundPage = props => {
    const { data, errors } = props

    if (errors) {
        return (
            <GraphQLErrorList errors={errors} />
        )
    }

    const page = data && data.page
    const pages = page && page.links.map((page, index) => {

        if (page._id === "home") {
            page.title = "Fossland + Foss"
        }

        if (page._type === "person") {
            page.title = page.name
        }

        return page

    })

    if (!page) {
      throw new Error(
        'Missing page data. Open the studio at http://localhost:3333 and add "About" page data and restart the development server.'
      )
    }

    return (
        <Container {...page}>
            <Layout backgroundColor="#ccc" pages={[page, ...pages]} {...page}>
                <Article {...page} />
            </Layout>
        </Container>
    )

    
}

export default NotFoundPage
